import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Header = () => {

  const [isActive, setActive] = useState(false);

  const handleToggle = () => {
    setActive(!isActive);
  };

  let menu = [
    {
      path: "/#about",
      name: "About",
      submenu: [],
      class: "nav__about",
    },
    {
      path: "/#games",
      name: "Games",
      submenu: [],
      class: "nav__games",
    },
    {
      path: "/#contact",
      name: "Contact",
      submenu: [],
      class: "nav__contact",
    },
    {
      path: "https://blog.twoj.io/",
      name: "Blog",
      submenu: [],
      class: "nav__blog",
    },
  ];

  class Soc extends React.Component {
    render() {
      return (
      <ul className='soc__icons soc__icons--header'>
      <li className='soc__icons-title'>
      <a href='https://twitter.com/'>
          <img
              alt='Twitter'
              className='soc__icons--image'
              src='../images/twitter.svg'
          />
      </a>
      </li>
      <li className='soc__icons-title'>
      <a href='https://facebook.com/'>
          <img
              alt='Facebook'
              className='soc__icons--image'
              src='../images/facebook.svg'
          />
      </a>
      </li>
  </ul>);
    }
  }

  return (
    
    <div className="header__container container relative">
    <Link
      className="header__leftside navbar-brand"
      to="/"
    >
      <img
          alt='twoj'
          className='header__leftside-logo'
          src='/images/logo.svg'
      />
    </Link>
    <header className='hdr'>
      <div className='hdr__container container'>
        <ul
          className={`${
            isActive
              ? "hdr__nav toggle nav active"
              : "hdr__nav toggle nav"
          }`}
        >
          {menu.map((link) => (
            <li
              className={
                link.submenu && link.submenu.length > 0
                  ? "hdr__nav-li nav-item hdr__submenu-parent"
                  : "hdr__nav-li nav-item"
              }
              key={link.name}
            >
              <a
                className={"hdr__nav-link nav-link headm " + link.class}
                href={link.path}
                onClick={handleToggle}
              >
                {link.name}
              </a>
              {link.submenu && link.submenu.length > 0 ? (
                <ul className="hdr__submenu">
                  {link.submenu.map((sublink) => (
                    <li className="hdr__submenu-item" key={sublink.name}>
                      <a
                        className={"hdr__nav-link nav-link " + sublink.class}
                        href={sublink.path}
                        onClick={handleToggle}
                      >
                        {sublink.name}
                      </a>
                    </li>
                  ))}
                </ul>
              ) : null}
            </li>
          ))}
        </ul>

        <button
          className={`${
            isActive
              ? "app hdr__menu toggle active"
              : " app hdr__menu toggle"
          }`}
          onClick={handleToggle}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </header>
    <a className="btn btn--red" href='#games'>Play now</a>
    </div>
  );
};

export default Header;
